<template>
  <div v-html="html"></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'formulario-preview',
  props: {
    formularioId: {
      type: String,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      html: "",
      loading: false,
      formularioFull: null,
    }
  },
  methods: {
    ...mapActions(["setAlerta"]),
    getPage() {
      const self = this

      if (self.formularioId) {
        self.$http.get(`/report/form/preview/${self.formularioId}`)
          .then((response) => {
            self.loading = true
            self.html = response.data;
          })
          .catch((e) => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: e?.response?.data?.message || "Não foi possível gerar o formulário!"
            })
            self.loading = false
          });
      }
    },
  },
  
  created() {
    this.getPage()
  }
}
</script>
