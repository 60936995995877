<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title class="text-center justify-center">
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-subtitle class="text-center justify-center">
        <v-row justify="center">
          <v-col align="center">
            <span>{{ subtitle }}</span>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-actions>
        <v-col align="center">
          <v-row justify="center">
            <v-btn class="ma-1 primary" @click="confirmBottonLeft">{{ nameBottonLeft }}</v-btn>
            <v-btn class="ma-1 error" @click="confirmBottonRight">{{ nameBottonRight }}</v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm-message",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nameBottonLeft: {
      type: String,
      default: "",
    },
    nameBottonRight: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    dialog(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    confirmBottonLeft() {
      this.$emit("confirmBottonLeft");
      this.dialog = false;
    },
    confirmBottonRight() {
      this.$emit("confirmBottonRight");
      this.dialog = false;
    },
  },
};
</script>