<template>
  <v-container fluid>
    <v-navigation-drawer right clipped app :width="sideBarWidth" v-model="sideBar" disable-resize-watcher mobile-breakpoint="960">
      <exame-sidebar v-if="exame.id" :exame="exame" :aba="abaSidebar" @close="sideBar=false"></exame-sidebar>
    </v-navigation-drawer>
    <v-card flat>
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card-title class="pb-0">
          <v-row dense class="mb-1 pl-1" align="center">
            <v-col cols="auto" class="pl-0">
              <v-tooltip open-delay="800" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="primary" icon @click="dialog=true" v-bind="attrs" v-on="on">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>  
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="exame.tipoExame.descricao" cols="auto" class="headline primary--text text-capitalize pr-0">
              {{ exame.tipoExame.descricao.toLowerCase() }}:
            </v-col>
            <v-col v-if="exame.solicitacao.paciente.nome" cols="auto" class="headline primary--text text-capitalize pr-0">
              {{ exame.solicitacao.paciente.nome }}
            </v-col>
            <v-col cols="auto" class="px-0">
              <v-hover open-delay="300" v-slot="{ hover }">
                <v-btn v-show="qrCodeImage" icon color="primary">
                  <v-icon>mdi-qrcode</v-icon>
                  <div v-if="hover" class="qrcode_container">
                    <div class="qrcode">
                      <v-img contain :src="qrCodeImage"></v-img>
                    </div>
                  </div>
                </v-btn>
              </v-hover>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="px-0" v-if="!sideBar">
              <v-tooltip open-delay="800" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon outlined color="primary" @click="sideBar=true" v-bind="attrs" v-on="on">
                    <v-icon>mdi-information-variant</v-icon>
                  </v-btn>  
                </template>
                Mais Informações
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col id="top-side-atendimento" cols="12" v-if="modeloFormularios">
              <v-chip-group
                active-class="blue blue--text text--accent-4"
                v-model="modeloFormulario"
                column
              >
                <v-chip v-for="(modeloFormulario, index) in modeloFormularios" :key="index"
                  :value="modeloFormulario"
                  outlined
                  label
                  :ripple="false"
                >
                  {{ modeloFormulario.nome }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col id="exame-report" cols="12" md="12" v-if="!isExameLaudoExterno">
              <v-col cols="auto" class="mt-0 pa-0" v-if="(isTherePermissionAssinadoCoAssinado() || !isExameAssinadoCoAssinado()) && !isThereAdendo">
                <formulario-create-edit v-if="formularioExameLaudo.formulario.nome" ref="formView"
                  :key="formularioExameLaudo.formulario.nome"
                  :formularioParam="formularioExameLaudo.formulario"
                  :storageNameParam="getStorageName"
                  :saveBottonOff="true"
                  :autoSaveUpdate="autoSaveUpdate"
                  :formularioRascunho="formularioRascunho"
                  v-model="executeSaveUpdateForms"
                  @confirmSaveForms="formularioLaudoSavedUpdated"
                ></formulario-create-edit>
              </v-col>
              <v-col cols="auto" class="mt-0 px-0 pt-0" v-else>
                <v-card outlined>
                  <v-card-text>
                    <formulario-preview :formularioId="formularioExameLaudo.formulario._id"></formulario-preview>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-row justify="start" class="text-center pt-0" v-if="isExameAssinadoCoAssinado() && isExameFormularioExameLaudo">
                <v-col cols="auto">
                  <v-chip small outlined style="border-color: transparent;">
                    <strong v-text="mensagemExameAssinado()"></strong>
                  </v-chip>
                </v-col>
                <v-col cols="auto" v-if="exame.statusExame.id == 5">
                  <v-chip small outlined style="border-color: transparent;">
                    <strong v-text="mensagemExameCoAssinado()"></strong>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Tratamento para exibição dos adendos -->
          <v-card-title v-if="getFormularioExames && getFormularioExames.length != 0 " class="headline primary--text">
            <span>Adendos</span>
          </v-card-title>
          <v-card outlined class="my-1" v-for="item in getFormularioExames" :key="item.id">
            <v-card-text>
              <formulario-preview :formularioId="item.formulario._id" :key="item.formulario._id"></formulario-preview>
            </v-card-text>
            <v-card-subtitle>
              <v-chip small outlined style="border-color: transparent;">
                <strong v-text="mensagemFormularioExameAssinatura(item)"></strong>
              </v-chip>
            </v-card-subtitle>
          </v-card>
          <!-- Tratamento para exibição dos adendos -->
        </v-card-text>
        <v-card-text v-show="exameAdendoSelected">
          <exame-adendo-create-edit v-model="exameAdendoSelected" :headersOff="true" :titleOff="false" :cancelBottonOff="true" @objectReturn="getExameAdendoReturn" />
        </v-card-text>
        <v-card-actions>
          <v-row justify="end">
            <v-btn class="text-capitalize ma-1" min-width="110" color="secondary" @click="dialog=true">
              cancelar
            </v-btn>
            <template v-if="isValidChangeStatus(2)">
              <v-btn class="text-capitalize ma-1" min-width="110" color="warning" type="submit" @click.prevent="onSubmit(2)">
                {{ changeStatusLabels.get(2) }}
              </v-btn>
            </template>
            <template v-if="isValidChangeStatus(6)">
              <v-btn class="text-capitalize ma-1" min-width="110" color="primary" type="submit" @click.prevent="onSubmit(6)">
                {{ changeStatusLabels.get(6) }}
              </v-btn>
            </template>
            <template v-if="isValidChangeStatus(4)">
              <v-btn class="text-capitalize ma-1" min-width="110" color="primary" type="submit" @click.prevent="onSubmit(4)">
                {{ changeStatusLabels.get(4) }}
              </v-btn>
            </template>
            <template v-if="isValidChangeStatus(5)">
              <v-btn class="text-capitalize ma-1" min-width="110" color="primary" type="submit" @click.prevent="onSubmit(5)">
                {{ changeStatusLabels.get(5) }}
              </v-btn>
            </template>
            <template v-if="isValidChangeStatus(7)">
              <v-btn class="text-capitalize ma-1" min-width="110" color="primary" type="submit" @click.prevent="onSubmit(7)">
                {{ changeStatusLabels.get(7) }}
              </v-btn>
            </template>
          </v-row>
        </v-card-actions>
        <alerta></alerta>
      </v-form>
    </v-card>
    <confirm-leave-exame-create-edit
      v-model="dialog"
      @closeAndUnblockExame="closeAndUnblockExame"
      @closeExame="closeExame"
    ></confirm-leave-exame-create-edit>
    <confirm-message
      v-model="changeModeloFormularioDialog"
      :title="'Alteração de modelo de laudo'"
      :subtitle="`Todos os dados do laudo atual serão perdidos.\nDeseja prosseguir? `"
      :nameBottonLeft="'Confirmar'"
      :nameBottonRight="'Cancelar'"
      @confirmBottonLeft="changeModeloFormulario"
      @confirmBottonRight="cancelChangeModeloFormulario"
    ></confirm-message>
  </v-container>
</template>

<script>
import Alerta from '@/components/comum/Alerta.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import ConfirmLeaveExameCreateEdit from './ConfirmLeaveExameCreateEdit.vue';
import FormularioCreateEdit from '../../components/forms/FormularioCreateEdit.vue';
import ConfirmMessage from '../../components/comum/ConfirmMessage.vue';
import ExameAdendoCreateEdit from './ExameAdendoCreateEdit.vue';
import { getModeloFormulariosByTipoFormularioCodigo } from '@/utils/formUtils'
import { getPerfilId } from '../../seguranca';
import ExameSidebar from './ExameSidebar.vue';
import FormularioPreview from '../../components/forms/FormularioPreview.vue';

export default {
  name: 'exame-create-edit',
  data () {
    return {
      exame: {
        solicitacao: {
          paciente: {},
          setor: {
            unidade: {}
          },
        },
        tipoExame: {},
        statusExame: {},
      },

      qrCodeImage: null,
      formularioExameLaudo: {
        formulario: {}
      },
      autoSaveUpdate: true,
      formularioRascunho: true,
      isExameFormularioExameLaudo: false,
      isThereFormularioExameLaudo: false,
      gravaFormularioAtendimento: false,
      modeloFormularios: [],
      modeloFormulario: null,
      mezzowFormEnableHoverBehavior: true,

      // flagRelatorioExterno: false,

      dialog: false,
      changeRoute: null,
      defaultChangeRoute: false,
      statusExameId: null,
      executeSaveUpdateForms: false,
      isExecuteSaveUpdateForms: false,
      laudoRascunhoFormularioNome: '',
      changeModeloFormularioDialog: false,
      exameAdendoSelected: null,

      changeStatusLabels: new Map(),

      sideBar: false,
      abaSidebar: 'dados-exame',
    }
  },
  components: {
    Alerta,
    ConfirmLeaveExameCreateEdit,
    FormularioCreateEdit,
    ConfirmMessage,
    ExameAdendoCreateEdit,
    ExameSidebar,
    FormularioPreview,
  },
  computed: {
    ...mapGetters([
      'ultimaTela'
    ]),
    getStorageName() {
      return this.exame.solicitacao.setor.unidade.storage;
    },
    // Tratamento para exibição dos adendos
    getFormularioExames() {
      let list = []
      let current = this.exame?.formularioExameLaudo?.formularioExamePai ? JSON.parse(JSON.stringify(this.exame.formularioExameLaudo.formularioExamePai)) : null
      while (current) {
        if (current) list.push(current)
        current = current?.formularioExamePai
      }
      return list
      .sort((a, b) => a.id - b.id)
      .map(a => {
        // Limpa a lista removendo os objetos formularioExamePai do retorno...
        delete a.formularioExamePai
        return a
      })
    },
    isThereAdendo() {
      let current = this.exame?.formularioExameLaudo?.formularioExamePai
      while (current) {
        if (current?.tipoFormulario?.codigo === "LAUDO_ADENDO") return true
        current = current?.formularioExamePai
      }
      return false
    },
    isExameLaudoExterno() {
      // Retorna se o exame ou o modelo são do tipo laudo externo
      return this.exame?.statusExame?.id == 7 || this.modeloFormulario?.code == "LAUDO_EXTERNO"
    },
    sideBarWidth() {      
      if (this.$vuetify.breakpoint.xsOnly) return "100%"
      else if (this.$vuetify.breakpoint.smOnly) return 400
      else if (this.$vuetify.breakpoint.mdOnly) return 480
      else if (this.$vuetify.breakpoint.lgOnly) return 600
      else return 720
    },
  },
  watch: {
    modeloFormulario () {
      if (this.exame.laudoRascunho?.formularioExameLaudo?.formulario?._id) {
        this.laudoRascunhoFormularioNome = this.formularioExameLaudo?.formulario?.nome
        this.formularioExameLaudo.formulario.nome = "_"
        this.changeModeloFormularioDialog = true
      } else {
        this.changeModeloFormulario()
      }
    }
  },
  methods: {
    ...mapActions([
      'setExameQuery',
      'setAlerta',
    ]),    
    loadModel () {
      var self = this
      if (this.$route.params.id) {
        this.$http.get(`/exame/${this.$route.params.id}`)
        .then(response => {
          self.exame = response.data

          // Desliga autoSaveUpdate do formulário caso o exame esteja assinado, co-assinado, rascunho, relatório preliminar ou laudo externo
          self.autoSaveUpdate = this.isExameAssinadoCoAssinado() || [2,6,7].includes(self.exame.statusExame.id) ? false : true
          // Marca se o exame já possui formularioExameLaudo
          self.isThereFormularioExameLaudo = self.exame?.formularioExameLaudo?.id ? true : false

          // Busca a imagem referente ao qrCode do exame
          this.getQrCodeImage()

          // Busca formularios existentes para o atendimento - Relatóio Médico
          this.buscarFormularioExameLaudo()

          // Bloqueia o exame para o médico que está editando
          this.bloquearExame()

          // Abre o componente ExameAdendoCaso a situação seja assinado
          this.isExameAssinadoCoAssinado() && this.isExameFormularioExameLaudo ? this.openExameAdendo(this.exame) : null
        })
        .catch(() => {
          self.setAlerta( {
            tipoAlerta : 'error',
            textAlerta : 'Erro ao carregar exame',
          })
        })
      }
    },
    saveUpdateExame () {
      const self = this

      if (self.exame.id) {
        let url = `/exame/changeStatus/${self.statusExameId}`
        self.$http.put(url, self.exame)
          .then(() => {
            self.setExameQuery({query: self.exame.nome})
            self.cancelar()
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Exame alterado com sucesso',
            })
          })
          .catch((error) => {     
            self.setAlerta( {
              tipoAlerta : 'error',
              textAlerta : error?.response?.data?.message || 'Erro ao alterar exame',
            })
          })
      } else{ 
        self.$http.post('/exame', self.exame)
          .then(() => {
            self.setExameQuery({query: self.exame.nome})
            self.cancelar()
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Exame criado com sucesso',
            })          
          })
          .catch(() => {
            self.setAlerta( {
              tipoAlerta : 'error',
              textAlerta : 'Erro ao criar exame',
            })
          })
      }
    },
    cancelar () {
      this.defaultChangeRoute = true
      if (this.ultimaTela) {
        this.$router.push({path: this.ultimaTela})
      } else {
        // Tratamento para que ao cancelar sempre retorne à lista
        this.$router.push({path: "/exame/list"})
      }        
    },
    async onSubmit(statusExameId) {
      const self = this

      if(!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
        return
      }

      let isFormularioExameForms = self.exame.formularioExameLaudo?.formulario?._id // Validar forms somente se existir...
      if (!self.isExameLaudoExterno && self.isThereFormularioExameLaudo && isFormularioExameForms && !self.$refs.formView.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos do formulário'
        })
        return
      }
      
      // Controla o fluxo do status do Exame conforme passado por parâmtro.
      if (Number.isInteger(statusExameId)) {
        self.statusExameId = statusExameId
      }

      if (!self.isExameLaudoExterno) {
        if (self.isExameFormularioExameLaudo) {
          self.formularioRascunho = false
        }
        self.executeSaveUpdateForms = true
        self.isExecuteSaveUpdateForms = true
        return
      }
      self.saveUpdateExame()
    },
    async formularioLaudoSavedUpdated(formulario) {
      if (formulario?._id) {
        this.formularioExameLaudo = { ...this.formularioExameLaudo, formulario: formulario }
        if (this.exame.formularioExameLaudo) {
          this.exame.formularioExameLaudo.formulario = formulario
        } else if (this.exame.laudoRascunho && this.exame.laudoRascunho.formularioExameLaudo) {
          this.exame.laudoRascunho.formularioExameLaudo.formulario = formulario
        } else {
          const formularioExameLaudo = {
            tipoFormulario: formulario.tipoFormulario,
            formulario: formulario
          }
          if (this.isExameFormularioExameLaudo) {
            this.exame.formularioExameLaudo = { ...formularioExameLaudo }
          } else {
            this.exame.laudoRascunho.formularioExameLaudo = { ...formularioExameLaudo }
          }
        }

        // Caso seja um exameLaudoRascunho, atualiza o laudo rascunho do exame!
        if (!this.isExameFormularioExameLaudo) {
          await this.updateLaudoRascunho()
        }

        // Controla o fluxo de gravação do formulario laudo
        if (this.isExecuteSaveUpdateForms) {
          if (!this.isExameFormularioExameLaudo) {
            this.exame.formularioExameLaudo = JSON.parse(JSON.stringify(this.exame.laudoRascunho.formularioExameLaudo))
            this.exame.laudoRascunho.formularioExameLaudo = null
            await this.updateLaudoRascunho()
          }

          this.saveUpdateExame()
        }
      }
    },
    async updateLaudoRascunho() {
      await this.$http.put(`laudoRascunho`, this.exame.laudoRascunho)
        .then((response) => {
          this.exame.laudoRascunho = { ...response.data }
        })
        .catch(() => {
          this.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Falha ao atualizar laudo Rascunho",
          })
        })
    },
    changeModeloFormulario() {
      // Mantem o id original do formulário para update ao invés de delete - insert.
      let formularioId = this.exame.laudoRascunho?.formularioExameLaudo?.formulario?._id

      // Realiza a mudança do formularioModelo
      if (this.modeloFormulario?._id) {
        const formularioModelo = { ...this.modeloFormulario, _id: formularioId, modeloFormulario: { _id: this.modeloFormulario._id }}
        this.formularioExameLaudo = { ...this.formularioExameLaudo, formulario: JSON.parse(JSON.stringify(formularioModelo))}
        this.isThereFormularioExameLaudo = true
        return
      } else if (this.isExameLaudoExterno) {
        this.formularioExameLaudo = { ...this.formularioExameLaudo, formulario: {} }
        this.isThereFormularioExameLaudo = true
        if (this.exame?.laudoRascunho?.formularioExameLaudo) {            
          let formularioIdToDelete = this.exame.laudoRascunho.formularioExameLaudo?.formulario?._id
          if (formularioIdToDelete) {
            // Deleta o formulário criado no laudo rascunho
            this.$http.delete(`forms/${formularioIdToDelete}`).then(response => console.log(response))
          }
          
          this.exame.laudoRascunho.formularioExameLaudo = this.formularioExameLaudo
          this.updateLaudoRascunho()
        }
        return
      }
      this.isThereFormularioExameLaudo = false
    },
    cancelChangeModeloFormulario() {
      this.modeloFormulario = null
      // Controla a mudança de formularioModelo quando for laudoRascunho
      if (this.exame.laudoRascunho?.formularioExameLaudo?.formulario) {
        this.formularioExameLaudo.formulario.nome = this.laudoRascunhoFormularioNome
      }
    },
    openExameAdendo(value) {
      this.exameAdendoSelected = value
    },
    getExameAdendoReturn(value) {
      if (value) {
        this.exame = { ...value }
        this.openExameAdendo(this.exame)
      }
    },
    async buscarFormularioExameLaudo() {
      const self = this;

      if (self.exame.formularioExameLaudo) {
        self.formularioExameLaudo = { ...self.exame.formularioExameLaudo }
        self.isExameFormularioExameLaudo = true
        return
      } else if (self.exame.laudoRascunho && self.exame.laudoRascunho.formularioExameLaudo) {
        self.formularioExameLaudo = { ...self.exame.laudoRascunho.formularioExameLaudo }
      }

      // Busca modeloFormularios ativos pelos código do tipoFormulario e pelo id da unidade (unidadeId is optional)
      const CODIGO_TIPO_FORMULARIO = "LAUDO_MEDICO"
      let modelosPermitidos = await getModeloFormulariosByTipoFormularioCodigo(CODIGO_TIPO_FORMULARIO, self.exame?.solicitacao?.setor?.unidade?.id, self.exame?.tipoExame?.id)
      if (!modelosPermitidos || modelosPermitidos.length == 0) {
        self.setAlerta({
          tipoAlerta: "warning",
          textAlerta: "Não foram encontrados modelos de laudo médico permitidos!",
        })
      }

      // Atribui os modelos de formulários permitidos para a lista de exibição
      if (modelosPermitidos && Array.isArray(modelosPermitidos)) {
        this.modeloFormularios = [{code: "LAUDO_EXTERNO", nome: "Laudo Externo"}, ...modelosPermitidos]
      }
    },
    getQrCodeImage() {
      const self = this

      self.$http.get(`/qrcode/gerarQR?texto=${process.env.VUE_APP_MEZZOW_WEB_CLIENT_URL}exame/edit/${self.exame.id}&altura=256&largura=256`, {
          responseType: 'blob'
        }).then(response => {
          self.qrCodeImage = URL.createObjectURL(response.data)
        }).catch(() => {
          self.setAlerta({
            tipoAlerta: "warning",
            textAlerta: "Não foi possível gerar o QrCode do exame!",
          })
        })
    },
    isExameAssinadoCoAssinado() {
      return [4,5].includes(this.exame.statusExame.id) ? true : false;
    },
    isTherePermissionAssinadoCoAssinado() {
      const statusExameId = this.exame?.statusExame?.id
      const medicoPrimeiraAssinaturaId = this.exame?.formularioExameLaudo?.medicoPrimeiraAssinatura?.id
      const medicoSegundaAssinaturaId = this.exame?.formularioExameLaudo?.medicoSegundaAssinatura?.id
      
      return statusExameId == 4 && medicoPrimeiraAssinaturaId == getPerfilId() ? true :
        statusExameId == 4 && medicoPrimeiraAssinaturaId != getPerfilId() ? true :
        statusExameId == 5 && medicoSegundaAssinaturaId == getPerfilId() ? true : false
    },
    isValidChangeStatus(value) {
      // Não permite alteração caso exista adendo
      if (this.isThereAdendo) return false

      const statusExame = this.exame?.statusExame?.id
      const medicoPrimeiraAssinaturaId = this.exame?.formularioExameLaudo?.medicoPrimeiraAssinatura?.id
      const medicoSegundaAssinaturaId = this.exame?.formularioExameLaudo?.medicoSegundaAssinatura?.id
      const usuarioAlteracaoId = this.exame?.formularioExameLaudo?.usuarioAlteracao?.id

      let validate = false
      switch (value) {
        case 2:
          validate = [1,2].includes(statusExame) && !this.isExameLaudoExterno
          if (validate) this.changeStatusLabels.set(value, "Rascunho")
          break;
        case 3:
          validate = [1,2,3].includes(statusExame)
          if (validate) this.changeStatusLabels.set(value, "Rascunho Digitador")
          break;
        case 4:
          validate = [1,2,3,4,6].includes(statusExame) && !this.isExameLaudoExterno
          if (validate) {
            this.changeStatusLabels.set(value, "Assinar")
            if (medicoPrimeiraAssinaturaId) {
              validate = getPerfilId() == medicoPrimeiraAssinaturaId
              if (validate) this.changeStatusLabels.set(value, "Atualizar Assinado")
            }
          }
          break;
        case 5:
          validate = [4,5].includes(statusExame) && !this.isExameLaudoExterno
          if (validate) {
            this.changeStatusLabels.set(value, "Co-Assinar")
            if (medicoSegundaAssinaturaId) {
              validate = getPerfilId() == medicoSegundaAssinaturaId
              if (validate) this.changeStatusLabels.set(value, "Atualizar Co-Assinado")
            } else if (medicoPrimeiraAssinaturaId) {
              validate = getPerfilId() != medicoPrimeiraAssinaturaId
              if (validate) this.changeStatusLabels.set(value, "Co-Assinar")
            }
          }
          break;
        case 6:
          validate = [1,2,3,6].includes(statusExame) && !this.isExameLaudoExterno
          if (validate) {
            this.changeStatusLabels.set(value, "Relatório Preliminar")
            if (usuarioAlteracaoId && statusExame === 6) {
              validate = getPerfilId() === usuarioAlteracaoId
              if (validate) this.changeStatusLabels.set(value, "Atualizar Rel. Preliminar")
            }
          }
          break;
        case 7:
          validate = [1].includes(statusExame) && this.isExameLaudoExterno
          if (validate) this.changeStatusLabels.set(value, "Confirmar")
          break;
        default:
          validate = false
          break;
      }
      return validate
    },
    parseDate(value) {
      return moment(value).format("DD/MM/YYYY HH:mm")
    },
    mensagemExameAssinado() {
      let dataAssinatura = moment(this.exame.formularioExameLaudo?.dataPrimeiraAssinatura).format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Assinado por Dr(a) " + this.exame.formularioExameLaudo?.medicoPrimeiraAssinatura?.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
    mensagemExameCoAssinado() {
      let dataAssinatura = moment(this.exame.formularioExameLaudo?.dataSegundaAssinatura).format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Co-Assinado por Dr(a) " + this.exame.formularioExameLaudo?.medicoSegundaAssinatura?.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
    mensagemFormularioExameAssinatura(formularioExame) {
      let dataAssinatura = moment(formularioExame?.dataCadastro).format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Assinado por Dr(a) " + formularioExame?.usuarioAlteracao?.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
    bloquearExame() {
      const self = this
      if (!self.exame.laudoRascunho) {
        let laudoRascunho = {
          exame: {
            id: self.exame.id
          }
        }

        self.$http.post(`laudoRascunho`, laudoRascunho)
          .then(response => {
            self.exame.laudoRascunho = response.data
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Não foi possível realizar o bloqueio do exame!",
            })
          })
      }
    },
    async desbloquearExame() {
      const self = this
      if (self.exame.laudoRascunho) {
        if (!self.isExameFormularioExameLaudo && self.exame?.laudoRascunho?.formularioExameLaudo?.formulario?._id) {
          await self.$http.delete(`forms/${self.exame.laudoRascunho.formularioExameLaudo.formulario._id}`)
        }
        await self.$http.delete(`laudoRascunho/${self.exame.laudoRascunho.id}`)
          .then(() => {
            self.exame.laudoRascunho = null
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Não foi possível realizar o desbloqueio do exame!",
            })
          })
      }
    },
    closeExame() {
      if (this.changeRoute) {
        this.changeRoute.next()
        this.changeRoute = null
      } else {
        this.cancelar()
      }
    },
    async closeAndUnblockExame() {
      await this.desbloquearExame()
      
      if (this.changeRoute) {
        this.changeRoute.next()
        this.changeRoute = null
      } else {
        this.cancelar()
      }

    },
    confirmExit(event) {
      event.preventDefault()
      event.returnValue = true
    }
  },
  created () {
    this.loadModel();
    window.addEventListener("beforeunload", this.confirmExit)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmExit)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.defaultChangeRoute) {
      this.changeRoute = { to, from, next }
      this.dialog = true
    } else {
      next()
    }
  }
}
</script>
<style scoped>
.lista-dados-atendimento {
  width: 96px;
}

.qrcode_container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
  outline: none;
}

.qrcode {
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  width: 100%;
  max-width: 500px;
  z-index: inherit;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
</style>
